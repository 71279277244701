import { Component } from '@angular/core';

@Component({
  selector: 'app-sponsorlist',
  templateUrl: './sponsorlist.component.html',
  styleUrl: './sponsorlist.component.scss'
})
export class SponsorlistComponent {
  sponsors = [
    {
      "name": "Hillyer's Midcity Ford",
      "url": "https://www.hillyersford.net",
      "logo": "/assets/images/sponsors/hillyersford-16x9.jpg",
      "sponsorLevel": "Diamond Sponsor - Scoreboard"
    },
    {
      "name": "Swire Coca-Cola",
      "url": "https://www.swirecc.com",
      "logo": "/assets/images/sponsors/swire-coca-cola-logo-16X9.png",
      "sponsorLevel": "Platinum Sponsor"
    },
    {
      "name": "Bountiful Farms",
      "url": "https://www.bountifulfarms.com",
      "logo": "/assets/images/sponsors/bountifulfarms-16x9.jpg",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Les Schwab - Wilsonville",
      "url": "https://www.lesschwab.com/stores/or/wilsonville/29175-sw-town-center-loop",
      "logo": "/assets/images/sponsors/lesschwabtires-16x9.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Rupp Family Builders",
      "url": "https://www.kitchensbyrupp.com",
      "logo": "/assets/images/sponsors/ruppfamilybuildersdark-16x9.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "WPI Builds",
      "url": "https://wpibuilds.com",
      "logo": "/assets/images/sponsors/westernpartitionsincorporated-16x9.jpg",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "AblePay",
      "url": "https://ablepayhealth.com",
      "logo": "/assets/images/sponsors/able-pay-health-16x9.png",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Active Water Sports",
      "url": "https://www.goosehead.com/agents/or/wilsonville/kyle-bunch/",
      "logo": "/assets/images/sponsors/active-water-sports-logo-16x9.png",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Audi Wilsonville",
      "url": "https://www.audiwilsonville.com",
      "logo": "/assets/images/sponsors/audi-wilsonville-logo-16x9.png",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Graham & Tooze Farm Store",
      "url": "https://www.grahamandtooze.com",
      "logo": "/assets/images/sponsors/grahamandtooze-16x9.jpg",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Nichols Family Agency-Allstate",
      "url": "https://agents.allstate.com/nichols-family-agency-wilsonville-or.html",
      "logo": "/assets/images/sponsors/nicholsfamilyagency-16x9.jpg",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Regenerative Orthopedic Center",
      "url": "https://www.rocpdx.com",
      "logo": "/assets/images/sponsors/roc-regenerative-orthopedic-center-logo-16x9.png",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Senestraro Family Orthodontics",
      "url": "https://wilsonville.senestrarofamilyortho.com/",
      "logo": "/assets/images/sponsors/senestrarofamilyorthodontics-16x9.png",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "24/7 Properties",
      "url": "https://www.247prop.com",
      "logo": "/assets/images/sponsors/247properties.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Barrier Pest Control",
      "url": "https://www.barrierpc.com",
      "logo": "/assets/images/sponsors/barrierpestcontrol2.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Bennett Accounting",
      "url": "https://www.bennett-tax.com",
      "logo": "/assets/images/sponsors/bennett-tax-accounting-logo.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Elite Development Northwest, LLC",
      "url": "https://ednorthwest.com",
      "logo": "/assets/images/sponsors/elite-development-northwest-16x9.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Flawless Aesthetics",
      "url": "https://hello-flawless.com/",
      "logo": "/assets/images/sponsors/flawless-aesthetics-logo-16x9.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Innovative Dance",
      "url": "https://www.innovativedance.net",
      "logo": "/assets/images/sponsors/innovativedance.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Interstate Roofing",
      "url": "https://www.interstateroofing.com",
      "logo": "/assets/images/sponsors/interstateroofing-16x9.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Jay Puppo State Farm Insurance",
      "url": "https://www.jaypuppo.com",
      "logo": "/assets/images/sponsors/state-farm-jake-puppo-16x9.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Kyle Bunch Goosehead Insurance",
      "url": "https://www.goosehead.com/agents/or/wilsonville/kyle-bunch/",
      "logo": "/assets/images/sponsors/kyle-bunch-goosehead-insurance-16x9.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Lance/'s Superior Auto Services",
      "url": "https://www.lancessuperiorauto.com",
      "logo": "/assets/images/sponsors/lancessuperiorautoservice.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Laue Team Real Estate",
      "url": "https://www.thelaueteam.com/",
      "logo": "/assets/images/sponsors/laueteamrealestate.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "McCounnaughey Real Estate",
      "url": "https://mcrehomes.com",
      "logo": "/assets/images/sponsors/mcconnaughey-real-estate.webp",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "McDonalds Greentree Enterprises",
      "url": "https://www.mcdonalds.com",
      "logo": "/assets/images/sponsors/mcdonalds.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Parker Johnstone's Wilsonville Honda",
      "url": "https://www.wilsonvillehonda.com",
      "logo": "/assets/images/sponsors/parker-johnstones-wilsonville-honda-white.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Stafford Beverage",
      "url": "https://www.staffordbeverage.com",
      "logo": "/assets/images/sponsors/stafford-beverage-16x9.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Therapeutic Associates Physical Therapy",
      "url": "https://www.therapeuticassociates.com",
      "logo": "/assets/images/sponsors/therapeutic-associates-physical-therapy-logo-16x9.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Tonkin Wilsonville Nissan",
      "url": "https://www.tonkinwilsonvillenissan.com",
      "logo": "/assets/images/sponsors/tonkinnissan-16x9.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Willamette Falls Financial",
      "url": "https://www.willamettefallsfinancial.com/",
      "logo": "/assets/images/sponsors/willamette-falls-financial-16x9.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Wilsonville Orthodontics - Dr. Restic",
      "url": "https://www.resticortho.com",
      "logo": "/assets/images/sponsors/wilsonvilleorthodontics-16x9.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Wilsonville Carpet and Tile",
      "url": "https://www.wilsonvillecarpetandtile.net/",
      "logo": "/assets/images/sponsors/wilsonville-carpet-tile-16x9.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Inflated Dreams",
      "url": "https://www.inflated-dreams.com",
      "logo": "/assets/images/sponsors/inflateddreams-16x9.jpg",
      "sponsorLevel": "In Kind Sponsor"
    },
    {
      "name": "Joy of LIfe Chiropractic",
      "url": "https://www.joyoflifechiropractic.com",
      "logo": "/assets/images/sponsors/joyoflifechiropractic-16x9.jpg",
      "sponsorLevel": "In Kind Sponsor"
    },
    {
      "name": "Pivot Marketing Group",
      "url": "https://www.askpivot.com",
      "logo": "/assets/images/sponsors/pivotmarketinggroup-16x9.jpg",
      "sponsorLevel": "In Kind Sponsor"
    },
    {
      "name": "UPS Store",
      "url": "https://www.locations.theupsstore.com/or/wilsonville",
      "logo": "/assets/images/sponsors/ups-store-logo-16x9.png",
      "sponsorLevel": "In Kind Sponsor"
    }   
  ];
}

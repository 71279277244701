<div class="container bg-grey" style="height: 100%;">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
          <span class="mx-2 text-center shrink-text">
            A BIG THANK YOU TO OUR SPONSORS!
          </span>
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        </h3>
      </div>
    <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 img-container text-center mx-auto" *ngFor="let sponsor of sponsors">
            <a [href]="sponsor.url" class="nav-link" target="_blank">
                <img [src]="sponsor.logo" [alt]="sponsor.name" class="rounded img-fluid " />
                <br />{{ sponsor.name }}
                <br />{{ sponsor.sponsorLevel }}
            </a>
        </div>
    </div>
    <br />
</div>
<div class="container bg-grey" style="height: 100%;">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
            <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo"
                class="d-none d-md-inline" />
            <span class="mx-2 text-center shrink-text">
                VARSITY ROSTER - 2024
            </span>
            <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo"
                class="d-none d-md-inline" />
        </h3>
    </div>

    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <img src="assets/images/2024/rosters/varsity/2024-wilsonville-football-varsity-team-photo.jpg"
                alt="Wilsonville Wildcat Varsity Football 2024" class="rounded img-fluid" />
        </div>
    </div>

    <div class="row mt-4 justify-content-center">
        <div *ngFor="let player of roster_dataSource.data" class="col-sm-6 col-md-4 col-lg-2 text-center mb-4">
            <div class="card bg-dark text-white border-0">
                <img src="assets/images/2024/rosters/varsity/{{player.image}}" alt="{{player.name}}"
                    class="card-img-top img-thumbnail">
                <div class="card-body">
                    <h5 class="card-title">#{{player.jersey}}</h5>
                    <p class="card-text">{{player.name}}
                        <br />
                        {{player.height}} {{player.weight}}
                        <br />
                        <a *ngIf="player.twitter" [href]="'https://x.com/' + player.twitter" target="_blank">
                            <!-- <img src="assets/images/misc/x-logo-white.png" alt="Twitter" width="20" /> -->
                            &#64;{{player.twitter}}
                        </a>
                        <br />
                        <a *ngIf="player.hUDL" [href]="player.hUDL" target="_blank">HUDL Profile</a>
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>